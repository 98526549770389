import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Marquee from "react-fast-marquee"
import styled, { css } from "styled-components"
import { shuffle } from "../../../helpers"
import { colorFromCms, smMediaQuery, switchBgColor } from "../../../theme/globalStyles"
import { NieuwsScrollerFragment, ScrollerFragment } from "../../../__generated__/graphql-gatsby"
import {
  NieuwsScrollerFragment as PreviewNieuwsScrollerFragment,
  ScrollerFragment as PreviewScrollerFragment,
} from "../../../__generated__/graphql-wp-sdk"

export interface WrapperProps {
  bg: Maybe<string>
}

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  width: 100%;
  color: ${({ theme }) => theme.palette.common.black};
  ${({ bg }) => switchBgColor(bg)};
  padding: ${({ theme }) => theme.spacing(16)}px 0;

  ::after,
  ::before {
    position: absolute;
    top: 0;
    bottom: 0;
    content: "";
    z-index: 2;
    width: 75px;
    background: ${({ bg }) =>
      `linear-gradient(to right, ${colorFromCms(bg, 1)}, ${colorFromCms(bg, 0)} )`};

    ${smMediaQuery(css`
      width: 42px;
    `)}
  }

  ::before {
    left: 0;
  }

  ::after {
    right: 0;
    transform: rotateZ(180deg);
  }
`

const Img = styled(GatsbyImage)`
  margin: 0 ${({ theme }) => theme.spacing(10)}px;

  ${smMediaQuery(css`
    margin: 0 ${({ theme }) => theme.spacing(4)}px;
  `)}
`
const PreviewImg = styled.img`
  height: 100px;
  margin: 0 ${({ theme }) => theme.spacing(10)}px;

  ${smMediaQuery(css`
    margin: 0 ${({ theme }) => theme.spacing(4)}px;
  `)}
`

interface IProps {
  block:
    | ScrollerFragment
    | NieuwsScrollerFragment
    | PreviewScrollerFragment
    | PreviewNieuwsScrollerFragment
}

const Scroller: React.FC<IProps> = ({ block }) => {
  const bg =
    block.__typename === "WpPage_PageBuilder_PageBuilder_Scroller" ||
    block.__typename === "Page_PageBuilder_PageBuilder_Scroller"
      ? block.kleur
      : "white"

  const list = shuffle(block?.list ? [...block.list] : [])

  return (
    <Wrapper bg={bg}>
      <Marquee speed={32} gradient={false}>
        {list.map((img, i: number) => {
          const alt = img?.img?.__typename === "WpMediaItem" ? String(img.img.altText) : "preview"

          if (img?.img?.__typename === "WpMediaItem") {
            return (
              <Img
                key={i}
                image={img?.img?.localFile?.childImageSharp?.gatsbyImageData}
                alt={alt}
              />
            )
          }

          if (img?.img?.__typename === "MediaItem") {
            return <PreviewImg src={String(img.img.mediaItemUrl)} alt={alt} />
          }

          return null
        })}
      </Marquee>
    </Wrapper>
  )
}

export default Scroller
